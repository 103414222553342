<template>
    <div class="profitionalBody-bg">
        <h1 style="color:#4b5c73">{{ $t('header.nav6_desc')}}</h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .profitionalBody-bg {
        margin-top : 3em;
    }
</style>